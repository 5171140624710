import React from 'react';
import type { Placement } from '@floating-ui/react';

import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/Tooltip';
import { IconInfoCircle } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

export interface VCTooltipProps {
  children: React.ReactNode;
  Icon?: () => JSX.Element;
  placement?: Placement;
}

const VCTooltip = ({
  children,
  Icon = () => <IconInfoCircle width={17} height={17} />,
  placement = 'top',
}: VCTooltipProps) => {
  return (
    <>
      <span className={styles.vcTooltip}>
        <Icon />
      </span>
      <Tooltip placement={placement} width={600}>
        <TooltipTrigger className={styles.tooltipButton} aria-label="View weekly price disclaimer">
          <Icon />
        </TooltipTrigger>
        <TooltipContent>{children}</TooltipContent>
      </Tooltip>
    </>
  );
};

export default VCTooltip;
