import React, { useCallback, useMemo, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import Button from '@/components/Button';
import FavouritesMsg from '@/components/Toast/components/FavouritesMsg';
import { TooltipBoundary } from '@/components/Tooltip';
import { MemoizedVCCarousel } from '@/components/VehicleCard/VCCarousel';
import VCDealerComments from '@/components/VehicleCard/VCDealerComments';
import VCLabel from '@/components/VehicleCard/VCLabel';
import VCLocation from '@/components/VehicleCard/VCLocation';
import VCSpecs from '@/components/VehicleCard/VCSpecs';
import VCTooltip from '@/components/VehicleCard/VCTooltip';
import useRepaymentText from '@/hooks/useRepaymentText';
import useStore from '@/hooks/useStore';
import { IconHeart } from '@/images/icons/tabler-icons';
import { extractStockPhotos, getGovernmentChargesString, getPrettyComments } from '@/lib/StockItemHelper';
import { updateableToast } from '@/lib/ToastUtils';
import { getPrettyPriceFormat, toTitleCase } from '@/lib/utils';

import HeartFilled from '@/images/icons/heart-filled.svg';

import DistinguishedIcons from '../DistinguishedIcons';

import styles from './styles.module.scss';

interface VehicleCardProps {
  vehicle: StockItem;
  labelIcon?: React.FC<React.SVGAttributes<SVGAElement>>;
  labelText?: string;
  // defaults
  /** display shortlist options; default false */
  showShortlist?: boolean;
  /** display CTA button; default false */
  showCTAButton?: boolean;
  /** display dealer comments, if enabled via CMS & available; default true */
  showDealerComments?: boolean;
  /** preload first image; default false */
  preload?: boolean;
  /** disable user navigation to stock page; default false */
  disableNavigation?: boolean;
  /** show only the first image and remove pagination; default false */
  showOnlyFirstImage?: boolean;
  /** display vehicle location; default true */
  showLocation?: boolean;
  /** display vehicle specs; default true */
  showSpecs?: boolean;
  /** set card height to 100% to fit a container; default false */
  fitContainer?: boolean;
  /** display vehicle adjusted price with tradein active; default false */
  showAfterTradeInPrice?: boolean;
  /** display the weekly price of finance; default true */
  showWeeklyPrice?: boolean;
  /** show only apply to most viewed carousel */
  isFromMostViewCarousel?: boolean;
  /** hide condition tag */
  hideCondition?: boolean;
  /** display vehicle stock number; default false */
  showStockNumber?: boolean;
  /** hide indicative interest rate */
  hideIndicativeInterestRate?: boolean;
}

const VehicleCard = ({
  vehicle,
  labelIcon,
  labelText,
  showShortlist = false,
  showCTAButton = false,
  showDealerComments = true,
  preload = false,
  disableNavigation = false,
  showOnlyFirstImage = false,
  showLocation = true,
  showSpecs = true,
  fitContainer = false,
  showAfterTradeInPrice = false,
  showWeeklyPrice = true,
  isFromMostViewCarousel = false,
  hideCondition = true,
  showStockNumber = false,
  hideIndicativeInterestRate = false,
}: VehicleCardProps) => {
  const [userHovering, setUserHovering] = useState(false);
  const shortlistStore = useStore('shortlistStore');
  const dealershipsMap = useStore('dealershipsMap');
  const {
    customization: { vehicleCardShowCtaButton, vehicleCardHideCondition, vehicleCardHideIndicativeInterestRate },
  } = useStore('globals');
  const { activeToastId, updateActiveToastId } = useStore('appState');
  const [repaymentText, , adjustedPrice, repayment, interestRate] = useRepaymentText(vehicle);

  const tradeInActive = showAfterTradeInPrice && !!vehicle?.price && adjustedPrice != vehicle.price;
  const showIndicativeInterestRate = !(hideIndicativeInterestRate && vehicleCardHideIndicativeInterestRate);
  const onShortlist = useCallback(() => {
    shortlistStore.toggle(vehicle);
    updateableToast(
      <FavouritesMsg msgType="count" carNumber={shortlistStore?.shortlist?.length} />,
      updateActiveToastId,
      activeToastId,
    );
  }, [shortlistStore, vehicle, activeToastId, updateActiveToastId]);

  const vehicleURL = useMemo(() => dealershipsMap.getVehicleStockSeoUrl(vehicle), [dealershipsMap, vehicle]);
  const navigateToURL = useCallback(() => window.location.assign(vehicleURL), [vehicleURL]);

  return (
    <TooltipBoundary
      overhang={8}
      className={cc({ [styles.vehicleCard]: true, [styles.fitContainer]: fitContainer })}
      onMouseEnter={() => setUserHovering(true)}
      onMouseLeave={() => setUserHovering(false)}
      onFocus={() => setUserHovering(true)}
      onBlur={() => setUserHovering(false)}
      onKeyDown={(e) => {
        if (disableNavigation || e.currentTarget !== e.target) return;
        return e.key === 'Enter' && navigateToURL();
      }}
      role={disableNavigation ? undefined : 'button'}
      tabIndex={0}
      data-testid="vehicleCard"
    >
      <div className={styles.carousel}>
        <MemoizedVCCarousel
          preload={preload}
          showShortlist={showShortlist}
          onShortlist={onShortlist}
          shortlisted={shortlistStore.hasShortlisted(vehicle.stockNo)}
          showDesktopNavigation={userHovering}
          onViewFullDetails={navigateToURL}
          images={extractStockPhotos(vehicle).slice(0, 4)}
          alt={`${toTitleCase(vehicle.make)} ${toTitleCase(vehicle.model)}`}
          disableNavigation={disableNavigation}
          showOnlyFirstImage={showOnlyFirstImage}
          onNavigate={navigateToURL}
        />
      </div>
      <div className={styles.wrapper}>
        <div
          className={cc({
            [styles.details]: true,
            [styles.detailsMostViewed]: isFromMostViewCarousel,
          })}
        >
          {!disableNavigation && (
            <a aria-hidden="true" tabIndex={-1} className={styles.link} href={vehicleURL}>
              <span aria-hidden={true} />
            </a>
          )}
          <div className={styles.heading}>
            <div
              className={cc({
                [styles.title]: true,
                [styles.titleMostViewed]: isFromMostViewCarousel,
              })}
            >
              {toTitleCase(vehicle.make)}{' '}
              <span className={isFromMostViewCarousel ? styles.model : ''}>{toTitleCase(vehicle.model)}</span>
            </div>
            {showShortlist && (
              <>
                {/* div will not display; exists only to space correctly */}
                <div className={styles.shortlistSpace}>
                  <IconHeart width={22} height={22} />
                </div>
                <button
                  className={cc({
                    [styles.shortlist]: true,
                    [styles.shortlisted]: shortlistStore.hasShortlisted(vehicle.stockNo),
                  })}
                  onClick={() => onShortlist()}
                  aria-label="add to favourites"
                >
                  {!!shortlistStore.hasShortlisted(vehicle.stockNo) ? (
                    <HeartFilled width={22} height={22} />
                  ) : (
                    <IconHeart width={22} height={22} />
                  )}
                </button>
              </>
            )}
          </div>
          <div className={styles.series}>{vehicle.variant}</div>
          {showSpecs && (
            <VCSpecs
              vehicle={vehicle}
              separator={VehicleCard.separator}
              specsToDisplay={
                hideCondition && vehicleCardHideCondition
                  ? VehicleCard.specsHideConditionToDisplay
                  : VehicleCard.specsToDisplay
              }
            />
          )}
          {showDealerComments && <VCDealerComments comments={getPrettyComments(vehicle)} />}
          <DistinguishedIcons vehicle={vehicle} />
          {!!labelText && <VCLabel Icon={labelIcon} text={labelText} />}
          {showLocation && <VCLocation vehicle={vehicle} separator={VehicleCard.separator} />}
          <div className={styles.priceWrapper}>
            <span className={cc({ [styles.price]: true, [styles.afterTrade]: !!tradeInActive })}>
              {getPrettyPriceFormat(tradeInActive ? adjustedPrice : vehicle.price)}
            </span>
            <span className={styles.governmentCharges}>
              {tradeInActive && (
                <>
                  <div className={styles.afterTrade}>After trade</div>
                  <div className={styles.dotSeparator}>{VehicleCard.separator}</div>
                </>
              )}
              {getGovernmentChargesString(vehicle)}
            </span>
          </div>
          {showWeeklyPrice && (
            <div className={styles.repaymentInformation}>
              <p className={isFromMostViewCarousel ? styles.priceInfo : ''}>
                est.{' '}
                {isFromMostViewCarousel ? (
                  <span className={styles.priceTag}>${repayment}/wk</span>
                ) : (
                  <strong>${repayment}/wk</strong>
                )}{' '}
                {showIndicativeInterestRate && (
                  <span data-testid="indicative-interest-rate">based on {interestRate}% p.a.</span>
                )}
              </p>
              <div className={styles.tooltip}>
                <VCTooltip>{<div dangerouslySetInnerHTML={{ __html: repaymentText }} />}</VCTooltip>
              </div>
            </div>
          )}
          {showCTAButton && vehicleCardShowCtaButton && (
            <div className={styles.cta}>
              <Button type="action-transparent" styleOverride={styles.button} href={vehicleURL}>
                View this car
              </Button>
            </div>
          )}
          {showStockNumber && <span className={styles.stockNumber}>Stock ID: {vehicle.stockNo}</span>}
        </div>
      </div>
    </TooltipBoundary>
  );
};

VehicleCard.separator = '•';
VehicleCard.specsToDisplay = ['listingType', 'year', 'transmission', 'mileage'] as Array<keyof StockItem>;
VehicleCard.specsHideConditionToDisplay = ['year', 'transmission', 'fuelType', 'mileage'] as Array<keyof StockItem>;

export default observer(VehicleCard);
