import React, { useMemo } from 'react';

import { getMakeModel, getPrettyMileage, getRawFuelType, getShortTransmission } from '@/lib/StockItemHelper';

import styles from './styles.module.scss';

interface VCSpecsProps {
  vehicle: StockItem;
  separator: string;
  specsToDisplay: Array<keyof StockItem>;
  asText?: boolean;
}

// Map any formatting functions
const formatMap: Partial<Record<keyof StockItem, (x: StockItem) => string>> = {
  make: getMakeModel,
  transmission: getShortTransmission,
  mileage: getPrettyMileage,
  fuelType: getRawFuelType,
};

const VCSpecs = ({ vehicle, separator, specsToDisplay, asText }: VCSpecsProps) => {
  const specs = useMemo(() => {
    return specsToDisplay
      .map((p) => {
        if (formatMap[p]) {
          return formatMap[p]!(vehicle);
        }
        return vehicle[p];
      })
      .filter((f) => !!f);
  }, [specsToDisplay, vehicle]);

  if (!specs.length) return null;

  if (asText) {
    return (
      <div className={styles.vcSpecs}>
        {specs.map((spec, i) => (
          <React.Fragment key={i}>
            {spec?.toString()}
            {i !== specs.length - 1 && ` ${separator} `}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.vcSpecs}>
      {specs.map((spec, i) => (
        <React.Fragment key={i}>
          <span>{spec?.toString()}</span>
          {i !== specs.length - 1 && <span>{separator}</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VCSpecs;
